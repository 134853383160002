import Vue from 'vue'
import {container} from 'tsyringe'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import {VoucherConfigHistoryBulkyPresenter} from '@/app/ui/presenters/VoucherConfigHistoryBulkyPresenter'
import {DEFAULT_PAGINATION, Utils} from '@/app/infrastructures/misc'
import {HistoryUploadBulkyVoucherConfig, dataHistoryUploadBulky} from '@/domain/entities/VoucherConfigHistoryBulky'
import {Pagination} from '@/domain/entities/Pagination'

export interface VoucherConfigHistoryBulkyParamsInterface {
  page: number | string
  perPage: number | string
  sortBy?: string
  keyword?: string
  startDate?: string
  endDate?: string
}

interface VoucherConfigHistoryBulkyState {
  isLoading: boolean
  dataHistoryUploadBulky: dataHistoryUploadBulky[];
  paginationHistoryUploadBulky: Pagination;
}

@Module({namespaced: true, store, name: 'voucher-config-history-bulky', dynamic: true})
class VoucherConfigHistoryBulkyController extends VuexModule implements VoucherConfigHistoryBulkyState {
  private presenter: VoucherConfigHistoryBulkyPresenter = container.resolve(
    VoucherConfigHistoryBulkyPresenter
  )

  isLoading = false
  dataHistoryUploadBulky: dataHistoryUploadBulky[] = []
  paginationHistoryUploadBulky: Pagination = new Pagination(1, DEFAULT_PAGINATION, 0)

  @Action({rawError: true})
  public getHistoryUploadBulky(params: VoucherConfigHistoryBulkyParamsInterface): void {
    this.setLoading(true)

    if (!params.startDate || params.startDate === '') {
      delete params.startDate
    }

    if (!params.endDate || params.endDate === '') {
      delete params.endDate
    }

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getHistoryUploadBulky(formattedParams)
      .then((res: HistoryUploadBulkyVoucherConfig) => {
        this.setDataHistoryUploadBulky(res)
      })
      .catch((error) => {
        Vue.notify({
          title: 'Fetch List History Upload Bulky Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  public setDataHistoryUploadBulky(res: HistoryUploadBulkyVoucherConfig): void {
    this.dataHistoryUploadBulky = res.data
    this.paginationHistoryUploadBulky = res.pagination
  }
}

export default getModule(VoucherConfigHistoryBulkyController)
