


















import {Component, Vue} from 'vue-property-decorator'
import {Utils} from '@/app/infrastructures/misc'
import Tabs from '@/app/ui/components/Tabs/index.vue'
import VoucherAndSkuList from '@/app/ui/views/Voucher/VoucherConfig/VoucherAndSKU/List/index.vue'
import SetLimitListPage from '@/app/ui/views/Voucher/VoucherConfig/SetLimit/List/index.vue'
import HistoryBulkyPage from '@/app/ui/views/Voucher/VoucherConfig/HistoryBulky/index.vue'

@Component({
  components: {
    Tabs,
    VoucherAndSkuList,
    SetLimitListPage,
    HistoryBulkyPage,
  },
})
export default class VoucherConfigMainPage extends Vue {
  currentTab = 'voucher-and-sku'
  itemTabs = [
    {label: 'Voucher & SKU', value: 'voucher-and-sku'},
    {label: 'Set Limit', value: 'set-limit'},
    {label: 'History Bulk', value: 'history-bulk'},
  ]

  created(): void {
    const queries = this.$route.query as Record<string, never>
    if (queries.tab) {
      this.currentTab = queries.tab
    }
  }

  private onTabsClick(tab: string): void {
    this.currentTab = tab
    Utils.onParamsChanged('tab', tab)
    Utils.onParamsChanged('page', 1)
    Utils.onParamsChanged('perPage', 20)
    Utils.onParamsChanged('keyword', '')
  }
}
