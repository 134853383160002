






















































































































































import {Vue, Component, Watch} from 'vue-property-decorator'
import IllustrationDownload from '@/app/ui/assets/ill_create_faq.vue'
import Card from '@/app/ui/components/Card/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import {DEFAULT_PAGINATION, Utils} from '@/app/infrastructures/misc'
import AddCircle from '@/app/ui/assets/add_circle.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import {HeaderObject, DataObject} from '@/app/ui/components/DataTableV2/type'
import EditIcon from '@/app/ui/assets/edit_icon_alt.vue'
import TrashIcon from '@/app/ui/assets/trash_icon_alt2.vue'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import ModalUploadBulky from '@/app/ui/components/Modal/Modalupload.vue'
import ModalUploadBulkyVoucherSku from '@/app/ui/views/Voucher/components/ModalUploadBulky/UploadBulkyVoucherSKU.vue'
import controller from '@/app/ui/controllers/VoucherConfigSKUController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'

@Component({
  components: {
    LoadingOverlay,
    ModalUploadBulkyVoucherSku,
    Modal,
    ModalAction,
    ModalUploadBulky,
    DataTableV2,
    PaginationNav,
    AddCircle,
    DropdownSelect,
    TextInput,
    Card,
    IllustrationDownload,
    Button,
    EditIcon,
    TrashIcon,
  }
})
export default class VoucherAndSKUList extends Vue {
  controller = controller
  filterOptions = [
    {label: 'Show All', value: 'SHOW_ALL'},
    {label: 'Active', value: 'ACTIVE'},
    {label: 'Pending', value: 'PENDING'},
    {label: 'Cancelled', value: 'CANCELLED'},
    {label: 'Expired', value: 'EXPIRED'},
  ]
  sortOptions = [
    {label: 'Latest', value: 'DESC'},
    {label: 'Oldest', value: 'ASC'},
  ]
  parameters = {
    page: 1,
    perPage: DEFAULT_PAGINATION,
    keyword: '',
    sort: this.sortOptions[0],
    filterBy: this.filterOptions[0],
  }

  headers: HeaderObject[] = [
    {
      title: 'SKU',
    },
    {
      title: 'Voucher Name',
    },
    {
      title: 'Amount',
    },
    {
      title: 'Action'
    },
  ]

  cancelID = 0

  showUploadModal = false
  showModalConfirm = false
  showModalSuccess = false
  modalActionData = {
    title: '',
    description: '',
    textPrimary: '',
    textSecondary: '',
  }

  created(): void {
    this.fetchData()
  }

  get dataItems(): DataObject[][] {
    return this.controller.listData.map((item) => [
      {
        value: item.skuCode,
      },
      {
        value: item.voucherName,
      },
      {
        value: Utils.toRupiah(item.voucherAmount),
      },
      {
        value: item.id,
      },
    ])
  }

  private openModal(): void {
    this.showUploadModal = true
  }

  private onDownloadTemplate(): void {
    window.open(
      'https://docs.google.com/spreadsheets/d/13QgvfeGHQ5F7WtK02Syov3iwk08-XGLY0vK6mDNfs8k/edit#gid=0'
    )
  }

  private fetchData(isReset = false): void {
    if (isReset) {
      this.parameters.page = 1
    }

    const params = {
      ...this.parameters,
      sort: this.parameters.sort.value,
      filterBy: this.parameters.filterBy.value,
    }
    controller.getAll(params)
  }

  actionShowConfirm(id: number): void {
    this.showModalConfirm = true
    this.modalActionData = {
      title: `Are you sure you want to delete?`,
      description: 'Data can’t be restore',
      textPrimary: 'Delete',
      textSecondary: 'Cancel',
    }
    this.cancelID = id
  }

  doActionModal(): void {
    this.showModalConfirm = false
    if (this.cancelID !== 0) {
      controller.delete(this.cancelID)
    }
  }

  @Watch('controller.successDelete')
  onChangeDeleteSuccess(isSuccessDelete: boolean): void {
    if (isSuccessDelete) {
      this.showModalSuccess = true
    }
  }

  onCloseSuccessModal(): void {
    this.fetchData()
  }
}
