import {VoucherConfigSKUInterface} from "@/data/payload/contracts/VoucherConfigSKURequest"

export interface VoucherConfigSKURequestInterface {
  skuCode: string
  voucherName: string
  amount: number
}

export class VoucherConfigSKURequest implements VoucherConfigSKUInterface {
  private readonly skuCode: string
  private readonly voucherName: string
  private readonly amount: number

  constructor(
    skuCode: string,
    voucherName: string,
    amount: number,
  ) {
    this.skuCode = skuCode
    this.voucherName = voucherName
    this.amount = amount
  }

  public toPayload(): string {
    return JSON.stringify({
      sku: this.skuCode,
      name: this.voucherName,
      amount: this.amount
    });
  }
}
