



















import { Component, Prop, Vue } from 'vue-property-decorator'

interface ItemTabs {
  key: number
  value: string
  label?: string
}

@Component
export default class Tabs extends Vue {
  @Prop({ type: Array, default: [] }) private itemTabs!: ItemTabs[]
  @Prop({ type: [Number, String], default: 0 }) private currentTab!:
    | number
    | string
  @Prop() private badgeData!: string[]

  get dataItems(): ItemTabs[] {
    return this.itemTabs
  }

  get activeTab(): string {
    return String(this.currentTab)
  }

  get badge(): string[] {
    return this.badgeData || new Array(this.dataItems.length).fill('')
  }
}
