import {container} from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import {UploadBulkyVoucherSKU, VoucherSKU, VoucherSKUList} from '@/domain/entities/VoucherConfigSKU'
import {Pagination} from '@/domain/entities/Pagination'
import {Utils} from '@/app/infrastructures/misc'
import {VoucherConfigSKUPresenter} from '@/app/ui/presenters/VoucherConfigSKUPresenter'
import {VoucherConfigSKURequest, VoucherConfigSKURequestInterface} from '@/data/payload/api/VoucherConfigSKURequest'
import {EnumStatusUpload} from '@/app/infrastructures/misc/Constants/upload'
import Axios, {CancelTokenSource} from 'axios'
import {UploadRequest} from '@/data/payload/api/UploadRequest'

export interface VoucherConfigSKUParamsInterface {
  page: number | string
  perPage: number | string
  sort?: string
  filterBy?: string
  keyword?: string
}

export interface VoucherConfigSKUState {
  isLoading: boolean
  isLoadingDelete: boolean
  isSave: boolean
  successDelete: boolean
  paginationData: Pagination
  listData: VoucherSKU[]
  detailData: VoucherSKU | null
  statusUploadBulky: EnumStatusUpload
  errUploadBulky: null | string
  forceStart: boolean
  dataRetry: number
  cancelToken: CancelTokenSource | undefined
  responseUploadBulky: UploadBulkyVoucherSKU | null
  fileUploaded: File | null
}

@Module({namespaced: true, store, name: 'voucher-config-sku', dynamic: true})
class VoucherConfigSKUController extends VuexModule implements VoucherConfigSKUState {
  private presenter: VoucherConfigSKUPresenter = container.resolve(
    VoucherConfigSKUPresenter
  )

  isLoading = false
  isLoadingDelete = false
  isSave = false
  successDelete = false
  paginationData: Pagination = new Pagination()
  listData: VoucherSKU[] = []
  detailData: VoucherSKU | null = null
  statusUploadBulky = EnumStatusUpload.START
  statusUploadBulkyDone = false
  errUploadBulky: null | string = null
  forceStart = false
  dataRetry = NaN
  cancelToken: CancelTokenSource | undefined = undefined
  responseUploadBulky: UploadBulkyVoucherSKU | null = null
  fileUploaded: File | null = null

  @Action({rawError: true})
  public getAll(params: VoucherConfigSKUParamsInterface) {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setListData(res)
      })
      .catch(err => {
        Vue.notify({
          title: 'Fetch List Voucher SKU Failed',
          text:
            err?.status >= 400 && err?.status < 500 ? err?.error?.message?.en : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({rawError: true})
  public getOne(id: number) {
    this.setLoading(true)

    this.presenter
      .getOne(id)
      .then(res => {
        this.setDetailData(res)
      })
      .catch(err => {
        Vue.notify({
          title: 'Fetch Detail Voucher SKU Failed',
          text:
            err?.status >= 400 && err?.status < 500 ? err?.error?.message?.en : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({rawError: true})
  public create(payload: VoucherConfigSKURequestInterface): void {
    this.setLoading(true)
    this.setSave(false)

    this.presenter
      .create(
        new VoucherConfigSKURequest(
          payload.skuCode,
          payload.voucherName,
          payload.amount,
        )
      )
      .then(() => {
        this.setSave(true)
      })
      .catch(error => {
        this.setSave(false)
        Vue.notify({
          title: 'Create SKU Code Voucher Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({rawError: true})
  public update(payload: { id: number; data: VoucherConfigSKURequestInterface }): void {
    this.setLoading(true)
    this.setSave(false)

    this.presenter
      .update(
        payload.id,
        new VoucherConfigSKURequest(
          payload.data.skuCode,
          payload.data.voucherName,
          payload.data.amount,
        )
      )
      .then(() => {
        this.setSave(true)
      })
      .catch(error => {
        this.setSave(false)
        Vue.notify({
          title: 'Update SKU Code Voucher Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }


  @Action({rawError: true})
  public delete(id: number) {
    this.setLoadingDelete(true)

    this.presenter
      .delete(id)
      .then(() => {
        this.setSuccessDelete(true)
      })
      .catch(err => {
        this.setSuccessDelete(false)
        Vue.notify({
          title: 'Delete Voucher SKU Failed',
          text:
            err?.status >= 400 && err?.status < 500 ? err?.error?.message?.en : 'Something went wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoadingDelete(false)
      })
  }

  @Action({rawError: true})
  public uploadBulky(payload: { file: File }): void {
    this.setStatusUpload(EnumStatusUpload.PROCESSING)
    this.setStatusUploadDone(false)
    this.setErrUploadBulky(null)
    this.setRespUploadBulky(null)
    this.setUploadFile(payload.file)

    // set token source for cancel request
    this.cancelToken = Axios.CancelToken.source()

    this.presenter
      .uploadBulky(new UploadRequest(payload.file), this.cancelToken)
      .then(() => {
        this.setStatusUploadDone(true)
        this.setStatusUpload(EnumStatusUpload.COMPLETE)
        this.setUploadFile(null)
        this.setErrUploadBulky(null)
      })
      .catch(error => {
        this.setStatusUploadDone(false)
        if (error.error.message.en.toLowerCase().includes('cancel')) {
          this.setStatusUpload(EnumStatusUpload.START)
        } else {
          this.setRespUploadBulky(null)
          this.setErrUploadBulky(error.error.message.id)
          this.setStatusUpload(EnumStatusUpload.FAILED)
        }
      })
  }

  @Action({ rawError: true })
  public getPreviewUploadBulky(payload: { file: File }): void {
    this.setStatusUpload(EnumStatusUpload.PROCESSING)
    this.setErrUploadBulky(null)
    this.setRespUploadBulky(null)
    this.setUploadFile(payload.file)
    this.setLoading(true)

    // set token source for cancel request
    this.cancelToken = Axios.CancelToken.source()

    this.presenter
      .previewUploadBulky(new UploadRequest(payload.file), this.cancelToken)
      .then(res => {
        this.setStatusUpload(EnumStatusUpload.COMPLETE)
        this.setRespUploadBulky(res)
      })
      .catch(error => {
        this.setRespUploadBulky(null)
        if (error.error.message.en.toLowerCase().includes('cancel')) {
          this.setStatusUpload(EnumStatusUpload.START)
        } else {
          this.setRespUploadBulky(null)
          this.setErrUploadBulky(error.error.message.id)
          this.setStatusUpload(EnumStatusUpload.FAILED)
        }
        Vue.notify({
          title: 'Fetch List Preview Upload Bulky Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({rawError: true})
  public cancelRequest(): void {
    if (this.cancelToken) {
      this.cancelToken.cancel()
    }
  }

  @Mutation
  private setRespUploadBulky(val: UploadBulkyVoucherSKU | null): void {
    this.responseUploadBulky = val
  }

  @Mutation
  private setListData(data: VoucherSKUList) {
    this.paginationData = data.pagination
    this.listData = data.data
  }

  @Mutation
  public setDetailData(data: VoucherSKU | null) {
    this.detailData = data
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }

  @Mutation
  private setLoadingDelete(bool: boolean) {
    this.isLoadingDelete = bool
  }

  @Mutation
  public setSave(bool: boolean) {
    this.isSave = bool
  }

  @Mutation
  public setSuccessDelete(bool: boolean) {
    this.successDelete = bool
  }

  @Mutation
  public setStatusUpload(enumStatus: EnumStatusUpload): void {
    this.statusUploadBulky = enumStatus
  }

  @Mutation
  public setStatusUploadDone(isDone: boolean): void {
    this.statusUploadBulkyDone = isDone
  }

  @Mutation
  public setForceStart(isForce: boolean): void {
    this.forceStart = isForce
  }

  @Mutation
  private setErrUploadBulky(err: null | string): void {
    this.errUploadBulky = err
  }

  @Mutation
  public setDatRetry(count: number): void {
    this.dataRetry = count
  }

  @Mutation setUploadFile(file: File | null): void {
    this.fileUploaded = file
  }
}

export default getModule(VoucherConfigSKUController)
